import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, chakra, Text } from '@chakra-ui/react';
import { Button, Col, Form, Input, InputNumber, Modal, Popconfirm, Row, Space, Table, Tag, notification } from 'antd';
import type { GetRef, InputRef, InputNumberProps } from 'antd';
import { PlusOutlined, DeleteOutlined, ReloadOutlined } from '@ant-design/icons';
import { NumericFormat } from 'react-number-format';
import { useParams } from "react-router-dom";
import useStockData from '../../../../hooks/useStockData';
import usePostTargetData from '../../../../hooks/customer/usePostTargetData';
import { useCustomer } from '../../../../hooks';
import './styles.scss'

type FormInstance<T> = GetRef<typeof Form<T>>;
type NotificationType = 'success' | 'info' | 'warning' | 'error';

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  dataIndex: keyof DataType;
  record: DataType;
  handleSave: (record: DataType) => void;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item style={{ margin: 0 }} name={dataIndex}>
        {
          dataIndex === 'symbol' ? (
            <Input ref={inputRef} onPressEnter={save} onBlur={save} minLength={3} maxLength={3} readOnly={record?.volume > 0 ? true : false} />
          ) : (
            <Input type='number' ref={inputRef} onPressEnter={save} onBlur={save} min={0} max={100} />
          )
        }
      </Form.Item>
    ) : (
      <div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

type EditableTableProps = Parameters<typeof Table>[0];

interface DataType {
  key: React.Key;
  symbol: string;
  volume: number;
  m_price: number;
  weight: number;
  t_weight: number;
  t_volume: number;
  trade_volume: number;
  trade_price: number;
  trade_value: number;
  trade_type: string;
  status: string;
}

type ColumnTypes = Exclude<EditableTableProps['columns'], undefined>;

interface ComponentProps {
  stockAllocKeys: any | null,
  assetAllocation: any | null,
}

interface StockDataType {
  // Define the properties and their types that are expected in the stockData object
  close: number;
  // Add more properties as needed
}

type ValueType = number;

interface RouteParams {
  customerId: string;
}

const useStockDataWithSymbol = (symbol: string): { stockData: StockDataType | null } => {
  return useStockData({ ticker: symbol });
};

const roundToNearestHundred = (num: number) => {
  return Math.floor(num / 100) * 100;
};

const EditableTable: React.FC<ComponentProps> = ({ stockAllocKeys, assetAllocation }) => {
  const totalValue = assetAllocation?.total_cash + assetAllocation?.total_stock;
  const { customerId }: RouteParams = useParams<RouteParams>();
  const { customer } = useCustomer({ account_id: customerId });
  const [dataSource, setDataSource] = useState<DataType[]>(stockAllocKeys || []);
  const [count, setCount] = useState(stockAllocKeys?.length + 1);
  const [portValue, setPortValue] = useState(totalValue ?? 0);
  const [selectedSymbol, setSelectedSymbol] = useState<string | null>(null);
  const { stockData } = useStockDataWithSymbol(selectedSymbol ?? '');
  const [rowData, setRowData] = useState<DataType | null>(null);
  const { mutate: postTargetData, data, error } = usePostTargetData();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type: NotificationType) => {
    api[type]({
      message: 'Thông báo',
      description:
        'Phân bổ danh mục thành công',
    });
  };

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    const jsonData = dataSource?.filter(item => item.trade_type === "buy" || item.trade_type === "sell");
    postTargetData('portfolio/customer/target', customerId, jsonData);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
      openNotificationWithIcon('success');
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const onChange: InputNumberProps['onChange'] = (value) => {
    setPortValue(totalValue + (value as ValueType));
  };

  const handleDelete = (key: React.Key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const handleReset = (row: DataType) => {
    row.t_weight = 0;
    setRowData(row);
  };

  const handleAdd = () => {
    const newData: DataType = {
      key: count,
      symbol: '-',
      volume: 0,
      m_price: 0,
      weight: 0,
      t_weight: 0,
      t_volume: 0,
      trade_volume: 0,
      trade_price: 0,
      trade_value: 0,
      trade_type: '',
      status: 'new',
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  const handleSave = async (row: DataType) => {
    setSelectedSymbol(row?.symbol);
    setRowData(row);
  };

  useEffect(() => {
    setPortValue(totalValue);
  }, [totalValue]);

  useEffect(() => {
    if (rowData && rowData?.symbol) {
      const newData = [...dataSource];
      const index = newData.findIndex((item) => rowData.key === item.key);
      const item = newData[index];
      if (rowData?.t_weight >= 0) {
        // Tính toán các giá trị cần thiết
        const t_volume = roundToNearestHundred((rowData?.t_weight / 100) * portValue / rowData?.m_price);
        const trade_volume = t_volume - rowData?.volume;
        const trade_value = trade_volume * rowData?.trade_price;

        // Cập nhật các giá trị trong đối tượng source
        rowData.t_volume = parseInt(t_volume?.toString()) ?? 0;
        rowData.trade_volume = parseInt(trade_volume?.toString()) ?? 0;
        rowData.trade_price = rowData?.trade_price ?? rowData?.m_price ?? 0
        rowData.trade_value = Math.abs(trade_value) ?? 0;
        rowData.trade_type = trade_volume > 0 ? 'buy' : (trade_volume < 0 ? 'sell' : '-');
      } else {
        rowData.t_volume = 0;
        rowData.trade_volume = 0;
        rowData.m_price = stockData?.close ?? 0;
        rowData.trade_price = rowData?.m_price ?? stockData?.close ?? 0;
        rowData.trade_value = 0;
        rowData.trade_type = '-';
      }

      newData.splice(index, 1, {
        ...item,
        ...rowData,
      });
      setDataSource(newData);
    }
  }, [rowData, selectedSymbol, stockData]);

  useEffect(() => {
    const newData = [...dataSource];

    // Lặp qua từng phần tử trong dataSource
    newData.forEach((source, index) => {
      if (source?.t_weight >= 0) {
        // Tính toán các giá trị cần thiết
        const t_volume = roundToNearestHundred((source?.t_weight / 100) * portValue / source?.m_price);
        const trade_volume = t_volume - source?.volume;
        const trade_value = trade_volume * (source?.trade_price ?? source?.m_price);

        // Cập nhật các giá trị trong đối tượng source
        source.t_volume = parseInt(t_volume?.toString()) ?? 0;
        source.trade_volume = parseInt(trade_volume?.toString()) ?? 0;
        source.trade_price = source?.trade_price ?? source?.m_price ?? 0;
        source.trade_value = Math.abs(trade_value) ?? 0;
        source.trade_type = trade_volume > 0 ? 'buy' : (trade_volume < 0 ? 'sell' : '-');
      }

      // Cập nhật phần tử trong mảng newData tại vị trí tương ứng
      newData[index] = { ...source };
    });

    // Cập nhật lại state với mảng mới đã được chỉnh sửa
    setDataSource(newData);
  }, [portValue]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const defaultColumns: (ColumnTypes[number] & { editable?: boolean; dataIndex: string })[] = [
    {
      title: 'Mã CK',
      dataIndex: 'symbol',
      key: 'symbol',
      align: 'center',
      editable: true
    },
    {
      title: 'Số lượng',
      dataIndex: 'volume',
      key: 'volume',
      align: 'right',
      width: 100,
      render: (text) => <NumericFormat value={text} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Giá thị trường',
      dataIndex: 'm_price',
      key: 'm_price',
      align: 'right',
      width: 120,
      render: (text) => <NumericFormat value={text} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Tỷ trọng',
      key: 'weight',
      dataIndex: 'weight',
      align: 'right',
      width: 80,
      render: (text) => <NumericFormat value={parseInt(text?.toFixed(0))} displayType={'text'} suffix={'%'} />,
    },
    {
      title: 'Tỷ trọng mục tiêu',
      key: 't_weight',
      dataIndex: 't_weight',
      editable: true,
      width: 80,
      align: 'right',
      render: (text) => <NumericFormat value={text > 0 ? text : 0} displayType={'text'} suffix={'%'} />,
    },
    {
      title: 'Số lượng mục tiêu',
      key: 't_volume',
      dataIndex: 't_volume',
      width: 100,
      align: 'right',
      render: (text) => <NumericFormat value={parseInt(text?.toFixed(0))} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Số lượng giao dịch',
      key: 'trade_volume',
      dataIndex: 'trade_volume',
      width: 100,
      align: 'right',
      className: 'trade_volume',
      render: (text) => <NumericFormat value={parseInt(text?.toFixed(0))} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Giá giao dịch',
      key: 'trade_price',
      dataIndex: 'trade_price',
      editable: true,
      width: 80,
      align: 'right',
      render: (_, record) => {
        return <NumericFormat value={parseInt(record?.trade_price).toFixed(0)} displayType={'text'} thousandSeparator={true} />;
      },
    },
    {
      title: 'Giá trị giao dịch',
      key: 'trade_value',
      dataIndex: 'trade_value',
      width: 80,
      align: 'right',
      render: (text) => <NumericFormat value={parseFloat(text?.toFixed(0))} displayType={'text'} thousandSeparator={true} />,
    },
    {
      title: 'Loại giao dịch',
      key: 'trade_type',
      dataIndex: 'trade_type',
      align: 'center',
      render: (_, record) => {
        if (record.trade_type === 'buy') {
          return <Tag color={'green'} style={{ textTransform: 'uppercase' }}>{record.trade_type}</Tag>
        } else if (record.trade_type === 'sell') {
          return <Tag color={'red'} style={{ textTransform: 'uppercase' }}>{record.trade_type}</Tag>
        }
        return '';
      },
    },
    {
      title: '',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      render: (_, record) =>
        record.status === 'new' ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <DeleteOutlined />
          </Popconfirm>
        ) : (
          <Popconfirm title="Sure to reset?" onConfirm={() => handleReset(record as DataType)}>
            <ReloadOutlined />
          </Popconfirm>
        ),
    },
  ];

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  
  const totalTradeValueBuy = dataSource?.filter(transaction => transaction?.trade_type === "buy")
    .reduce((sum, transaction) => sum + transaction?.trade_value, 0);

  const totalTradeValueSell = dataSource?.filter(transaction => transaction?.trade_type === "sell")
    .reduce((sum, transaction) => sum + transaction?.trade_value, 0);

  return (
    <div>
      {contextHolder}
      <Button type="link" onClick={showModal} style={{ position: 'absolute', top: 0, right: 0, padding: 0 }}>
        <chakra.img className='calculator' src={process.env.PUBLIC_URL + '/resources/images/calculator.svg'} />
      </Button>
      <Modal
        width={1000}
        open={open}
        closable={false}
        footer={[
          <Button key="cancel" type="default" onClick={handleCancel}>
            Hủy
          </Button>,
          <Button key="update" type="primary" style={{ backgroundColor: '#1F4172' }} loading={loading} onClick={handleOk}>
            Phân bổ
          </Button>,
        ]}
      >
        <Row align={'middle'} justify={'space-between'} style={{ marginBottom: 10 }}>
          <Col span={12} style={{ textAlign: 'left' }}>
            {
              customer && (
                <strong>{`${customer[0]['name']} - ${customer[0]['account_id']}`}</strong>
              )
            }
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Space>
              <Text>Số dư tiền mặt:</Text>
              <Box bg={'#D9D9D9'} py={2} px={10} borderRadius={'md'}>
                <NumericFormat value={assetAllocation?.total_cash} displayType={'text'} thousandSeparator={true} />
              </Box>
            </Space>
          </Col>
        </Row>
        <Box mt={5} mb={5}>
          <Button onClick={handleAdd} type="primary" style={{ marginBottom: 16 }} icon={<PlusOutlined />} />
          <Table
            components={components}
            rowClassName={() => 'editable-row'}
            bordered
            dataSource={dataSource}
            columns={columns as ColumnTypes}
            size={'small'}
            pagination={false}
          />
          <Row style={{ marginTop: 20 }}>
            <Col span={12} style={{ textAlign: 'left' }}>
              <Row>
                <Space>
                  <Text>Tổng giá trị danh mục:</Text><Text as='b'>
                    <NumericFormat value={totalValue} displayType={'text'} thousandSeparator={true} />
                  </Text>
                </Space>
              </Row>
              <Row>
                <Space>
                  <Text>Số tiền nạp thêm:</Text>
                  <InputNumber<number>
                    defaultValue={0}
                    min={0}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value?.replace(/\$\s?|(,*)/g, '') as unknown as number}
                    onChange={onChange}
                    style={{ width: '125px' }}
                  />
                </Space>
              </Row>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Row>
                <Space>
                  <Text>Tổng giá trị mua: </Text><Text as='b'>
                    <NumericFormat value={parseInt(totalTradeValueBuy?.toString()).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                  </Text>
                </Space>
              </Row>
              <Row>
                <Space>
                  <Text>Tổng giá trị bán: </Text><Text as='b'>
                    <NumericFormat value={parseInt(totalTradeValueSell?.toString()).toFixed(0)} displayType={'text'} thousandSeparator={true} />
                  </Text>
                </Space>
              </Row>
            </Col>
          </Row>
        </Box>
      </Modal>
    </div>
  );
};

export default EditableTable;
