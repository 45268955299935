// Transaction.tsx
import React from 'react';
import { Table } from 'antd';
import type { TableColumnsType } from 'antd';
import { NumericFormat } from 'react-number-format';
import CNumberToPercent from '../../../../components/CNumberToPercent';
import CLoading from '../../../../components/CLoading';
import './styles.scss'

type DataType = {
  key: string;
  asset: string;
  asset_type: string;
  c_value: number | null;
  c_price: number | null;
  m_value: number | null;
  m_price: number | null;
  pl: number | null;
  pct_pl: number | null;
  symbol?: string;
  volume?: number | null;
  weight?: number | null;
  t_weight?: number | null;
  t_volume?: number | null;
  extra_call?: number | null;
  extra_call_value?: number | null;
  children?: DataType[];
};

const columns: TableColumnsType<DataType> = [
  {
    title: '',
    dataIndex: 'symbol',
    key: 'symbol',
    width: 140,
    fixed: 'left',
  },
  {
    title: 'Khối lượng',
    dataIndex: 'volume',
    key: 'volume',
    align: 'right',
    width: 90,
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Giá vốn',
    dataIndex: 'c_price',
    key: 'c_price',
    align: 'right',
    width: 90,
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Giá thị trường',
    dataIndex: 'm_price',
    key: 'm_price',
    align: 'right',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Giá trị mua',
    dataIndex: 'c_value',
    key: 'c_value',
    align: 'right',
    width: 120,
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Giá trị thị trường',
    dataIndex: 'm_value',
    key: 'm_value',
    align: 'right',
    width: 130,
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Tỷ trọng',
    dataIndex: 'weight',
    key: 'weight',
    align: 'right',
    width: 80,
    render: (text) => <CNumberToPercent number={text} />,
  },
  {
    title: 'Lãi/lỗ',
    dataIndex: 'pl',
    key: 'pl',
    align: 'right',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Lãi/lỗ (%)',
    dataIndex: 'pct_pl',
    key: 'pct_pl',
    align: 'right',
    width: 90,
    render: (text) => text && <CNumberToPercent number={text} color={true} />,
  },
  {
    title: 'Tỷ trọng mục tiêu',
    dataIndex: 't_weight',
    key: 't_weight',
    align: 'right',
    className: 'lightyellow',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} suffix='%' />,
  },
  {
    title: 'Số lượng mục tiêu',
    dataIndex: 't_volume',
    key: 't_volume',
    align: 'right',
    className: 'lightyellow',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Mua / bán',
    dataIndex: 'extra_call',
    key: 'extra_call',
    align: 'right',
    width: 90,
    className: 'lightyellow',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  },
  {
    title: 'Giá trị mua / bán',
    dataIndex: 'extra_call_value',
    key: 'extra_call_value',
    align: 'right',
    className: 'lightyellow',
    render: (text) => <NumericFormat value={parseFloat(text).toFixed(0)} displayType={'text'} thousandSeparator={true} />,
  }
];

function groupAssets(assets: DataType[]): DataType[] {
  const grouped: { [key: string]: DataType } = {};

  assets?.forEach((asset, index) => {
    if (asset?.asset_type === 'CK') {
      if (!grouped[asset?.asset_type]) {
        grouped[asset?.asset_type] = {
          key: `group-${index}`,
          asset: asset.asset,
          asset_type: asset.asset_type,
          symbol: asset.asset,
          c_value: null,
          c_price: null,
          m_value: null,
          m_price: null,
          pl: null,
          pct_pl: null,
          volume: null,
          weight: null,
          t_weight: null,
          t_volume: null,
          extra_call: null,
          extra_call_value: null,
          children: []
        };
      }

      const group = grouped[asset.asset_type];
      group.children!.push({
        ...asset,
        key: `child-${index}`
      });

      group.c_value! += asset.c_value || 0;
      group.c_price! += asset.c_price || 0;
      group.m_value! += asset.m_value || 0;
      group.m_price! += asset.m_price || 0;
      group.pl! += asset.pl || 0;
      group.weight! += asset.weight || 0;
      group.t_weight! += asset.t_weight || 0;
    } else {
      const group = grouped['CK'];
      grouped[asset.asset_type] = {
        ...asset,
        symbol: asset.asset,
        c_value: null,
        c_price: null,
        m_value: asset.c_value,
        m_price: null,
        pl: null,
        pct_pl: null,
        volume: null,
        t_weight: 100 - (group?.t_weight || 0),
        t_volume: null,
        extra_call: null,
        extra_call_value: null,
        key: `asset-${index}`
      };
    }
  });

  return Object.values(grouped).map(group => {
    if (group.children) {      
      const pct_pl: number = (group.pl != null && group.c_value != null && group.c_value !== 0)
        ? parseFloat(((group.pl / group.c_value)).toString())
        : 0;
      return {
        ...group,
        c_price: null,
        c_value: group.c_value === 0 ? null : parseFloat(group.c_value?.toString() || '0'),
        m_price: null,
        m_value: group.m_value === 0 ? null : parseFloat(group.m_value?.toString() || '0'),
        weight: group.weight === 0 ? null : parseFloat(group.weight?.toString() || '0'),
        t_weight: group.t_weight === 0 ? null : parseFloat(group.t_weight?.toString() || '0'),
        pl: group.pl === 0 ? null : parseFloat(group.pl?.toString() || '0'),
        pct_pl: pct_pl === 0 ? null : parseFloat(pct_pl?.toString() || '0'),
      };
    }
    return group;
  });
}

interface ComponentProps {
  assetStatus: any | null
}

const Transaction: React.FC<ComponentProps> = ({ assetStatus }) => {

  if (!assetStatus) return <CLoading />;

  console.log('assetStatus', assetStatus);
  
  const data: DataType[] = groupAssets(assetStatus);

  return (
    <>
      <Table
        defaultExpandAllRows
        className={'c-ant-table-expend'}
        columns={columns}
        dataSource={data}
        size={'small'}
        scroll={{ y: 500 }}
        pagination={false}
      />
    </>
  );
};

export default Transaction;
